.admin__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.admin__content {
    background-color: #262626;
    padding: 2% 10%;
    display: flex;
    justify-content: center;
}

.infoForm{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 50px;
}
.infoForm select{
    width: 40%;
    flex: 1;
}
.infoForm input{
    width: 40%;
    flex: 1;
}

.break{
    flex-basis: 100%;
}

.result{
    text-align: left;
}

.result pre {
    -webkit-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
}

.content__global {
    width: 75%;
    margin: auto;
    text-align: left;
}

.content {
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    column-gap: 20px;
    width: 80%;
    margin: 0 auto;
    flex-wrap: wrap;
}

.title {
    font-size: 30px;
    font-family: "Montserrat Alternates Bold", serif;
    color: #980000;
    margin-bottom: 20px;
}

.categories {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 20px;
    width: 100%;
}

.subCategory {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    border-radius: 5px;
    width: 70%;
}

/* Stile specifico per ogni categoria */
.usersManagement {
    background-color: #1a2930;
    box-shadow: 0 0 10px #1a2930;
}

.reportsManagement {
    background-color: #301a1a;
    box-shadow: 0 0 10px #301a1a;
}

.filesManagement {
    background-color: #1a3030;
    box-shadow: 0 0 10px #1a3030;
}

.usersManagement h2,
.reportsManagement h2,
.filesManagement h2 {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.buttonGroup {
    display: flex;
    justify-content: center;
}

.button {
    margin: 0 10px;
}