.superBoxTree {
    border: 1px solid #6a6a6a;
    width: 90%;
    overflow: hidden;
    position: relative;
    font-size: 20px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    color: #000;
    background-color: transparent;
    margin: auto;
    border-radius: 3px;
    transition: all 0.2s ease;
}

.mainChildren {
    border-radius: 15px;
    float: right;
    width: 100%;
    margin: 0px;
    transition: all 0.2s ease;
}

.subChildren {
    border-radius: 8px;
    border: 1px solid #b7b7b7;
    background-color: #5a1414;
    color: white;
    width: 90%;
    margin: 6px 3% 6px 7%;
    transition: all 0.2s ease;
}

.subLeftChildren {
    grid-area: left;
    margin: auto auto;
    width: 95%;
    transition: all 0.2s ease;
}

.subRightChildren {
    grid-area: right;
    margin: auto auto;
    width: 95%;
    transition: all 0.2s ease;
}

.dragging {
    border: 3px solid #000000;
    background-color: #ffffff;
    opacity: 0.7;
    filter: alpha(opacity=70);
    z-index: 1000;
    transition: all 0.2s ease;
}

.popup-main {
    opacity: 0;
    display: none;
}

.popup-overlay {
    opacity: 1;
}

.popup-main.popup-open {
    opacity: 1;
    display: block;
}

.dragNDropButton {
    width: fit-content !important;
}