.social__body_Background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
}

.social__body_backgroundDiv {
    position: relative;
    height: 100vh;
    width: 100vw;
    background-color: transparent;
    background-size: cover;
    background-position: center;

    opacity: 1;
    transition: opacity .5s ease-in-out;
}

.social__body_backgroundInvisible {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none; /* Impedisce all'elemento invisibile di ricevere eventi */
}

.social__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.social__firstEmpty {
    height: 25%;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("@/commons/resources/images/logos/fiamma.png");
    background-size: calc((15vh + 15vw) / 2);
    background-repeat: no-repeat;
    background-position: center;
    margin: 1% 0 0.1% 0;
}

.social__socialBox {
    margin: 0 0 0 0;
    height: fit-content;
    box-shadow: 0 -5px 20px #262626; /* horizontal-offset vertical-offset 'blur' colour */
    background-color: #262626;
    padding: 25px 0;
}

.social__socialTitle {
    font-family: Nunito, sans-serif;
    font-size: 20px;
    font-weight: 900;
    width: 100%;
}

.social__socialElement {
    font-family: Nunito, sans-serif;
    font-size: 15px;
    font-weight: 400;
    width: 180px;
    margin: 0 0 10px 0;
    display: inline-grid;
    background-color: #3e3e3e;
    border: 1px solid #3e3e3e;
    border-radius: 10px;
    padding: 5px;
    grid-template-columns: 1fr 4fr;
}

.social__socialElement img {
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
}

.social__socialElement text {
    color: #c2c2c2;
    text-decoration: none;
    transition: all .3s ease-in-out;
}

.social__header {
    width: 100%;
    height: 40px;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.social__header__button {
    text-shadow: 0 0 4px #252525; /* horizontal-offset vertical-offset 'blur' colour */
    font-family: "Montserrat Alternates Bold", serif;
    margin: 0 10px;
    height: 40px;
    color: #c2c2c2;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s ease-in-out;
    cursor: pointer;
    min-width: 6%;
}

.social__header__special {
    text-shadow: 0 0 10px #0059ff; /* horizontal-offset vertical-offset 'blur' colour */
}

.social__header__evidenced {
    text-shadow: 0 0 1px #252525; /* horizontal-offset vertical-offset 'blur' colour */
    box-shadow: 0 0 20px #00b2ff; /* horizontal-offset vertical-offset 'blur' colour */
    color: transparent;
    padding: 2px 10px;
    background-color: #00b2ff;
    border-radius: 50px;
    font-weight: bold;
}

.social__header__button:hover {
    text-shadow: 0 0 5px #ff3131; /* horizontal-offset vertical-offset 'blur' colour */
}

.social__header__special:hover {
    color: #0066ff;
}

.social__header__evidenced:hover {
    text-shadow: 0 0 1px #252525; /* horizontal-offset vertical-offset 'blur' colour */
    color: transparent;
    background-color: #5fabff;
}


.social__footer_Background {
    box-shadow: 0 -5px 20px #262626; /* horizontal-offset vertical-offset 'blur' colour */
    background-color: #262626;
    width: auto;
    padding: 2% 10%;
}

.social__footer {
    color: white;
    width: 100%;
    display: flex;
    column-count: 3;
    flex-wrap: wrap;
    column-gap: 5%;
}

.social__footer > div {
    flex: 0 0 28%;
}

.social__footer_box {
    text-align: left;
    margin: 0 0 1% 0;
}

.social__footer_box_centered {
    text-align: center;
    margin: 0 0 1% 0;
}

.social__footer_title {
    font-family: Nunito, sans-serif;
    font-size: 20px;
    font-weight: 900;
    width: 100%;
}

.social__footer_title::after {
    content: "\a";
    white-space: pre;
}

.social__footer_text {
    font-family: Nunito, sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #b7b7b7;
    width: 100%;
}

.social__footer_links {
    color: #b7b7b7;
    text-align: left;
    width: 100%;
    border-bottom: 1px solid #b7b7b7;
}

.social__footer_links::after {
    content: "\a";
    white-space: pre;
}

.social__footer_links:hover {
    color: #ffffff;
}

.social__footer_button {
    color: white;
    background-color: #5fabff;
    border-radius: 5px;
    width: 80%;
}

.social__footer_button {
    color: white;
    background-color: #5fabff;
    border-radius: 5px;
    width: 80%;
}

.social__footer_button:hover {
    background-color: #2a77c9;
}

.social__footer_copyright {
    align-self: center;
    font-family: Nunito, sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #b7b7b7;
    width: 100%;
}