.pdfGrid {
    display: inline-grid;
    height: 100%;
    width: 100%;
    grid-template-areas: "download"
                         "pdf"
                         "buttons";
}

.pdfDownload {
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    grid-area: download;
}

.pdfDownload button {
    background-color: #878787;
    border: none;
    color: white;
    padding: 12px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 25px;
    transition: all 0.75s;
}

.pdfButton {
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    grid-area: buttons;
}

.pdfButton button {
    background-color: #878787;
    border: none;
    color: white;
    padding: 12px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 25px;
    transition: all 0.75s;
}

.pdfButton button:disabled {
    background-color: #000000;
}

.pdfButton button:disabled:hover {
    background-color: #000000;
}

.pdfButton button:hover {
    background-color: #3e3e3e;
    color: white;
}

.pdfCanvas {
    grid-area: pdf;
    width: 100%;
    height: 100%;
}

.pdfCanvas canvas {
    border: 1px solid #000000;
    border-radius: 20px;
    max-width: 775px;
}


.dateButton button {
    background-color: #878787;
    border: none;
    color: white;
    padding: 3px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 2px 4px;
    cursor: pointer;
    border-radius: 25px;
    transition: all 0.75s;
}
