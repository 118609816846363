/* Body e contenitore principale */
.groupsManager__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.groupsManager__content {
    background-color: #262626;
    padding: 20px;
}

.groupsManager__main {
    width: 90%;
    margin: 0 auto;
    color: #fff;
    text-align: center;
}

h1 {
    margin-bottom: 20px;
    font-size: 24px;
}

p {
    font-size: 16px;
    margin-bottom: 10px;
}

/* Sezione crea gruppo */
.create-group-section {
    margin-bottom: 20px;
    text-align: center;
}

.create-group-btn,
.save-group-btn {
    padding: 8px 16px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.create-group-btn:hover,
.save-group-btn:hover {
    background-color: #0056b3;
}

.create-group-form{
    margin: auto;
    width: 50%;
}
.back-button{
    float: left;
}

/* Campi input per i form */
.create-group-form input, .create-group-form textarea {
    border: 1px solid #555;
    background-color: #262626;
    color: white;
    padding: 10px;
    margin: 10px 0;
    min-height: 20px;
    max-height: 200px;
}
textarea {
    width: 100%;
    resize: vertical;
}

.create-group-form input:focus,
.create-group-form textarea:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 3px #007bff;
}
.save-group-btn {
    background-color: #28a745;
    border: none;
    color: white;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
}

.cancel-group-btn {
    background-color: #dc3545;
    border: none;
    color: white;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
}
.save-group-btn:hover {
    background-color: #218838;
}

.cancel-group-btn:hover {
    background-color: #c82333;
}

/* Tabelle */
.groups-table-wrapper {
    max-width: 90%;
    margin: 20px auto;
    overflow-x: auto;
}

.groupsTable {
    width: 100%;
    background-color: #242424;
    color: white;
    border-collapse: collapse;
}
.groupsTable th, .groupsTable td {
    border: 1px solid #444;
    padding: 10px;
    text-align: center;
}

.groupsTable th {
    background-color: #333;
    font-size: 14px;
    font-weight: bold;
}

.groupsTable td {
    font-size: 13px;
}

/* Descrizione */
.group-description {
    font-size: 13px;
    cursor: pointer;
}

.group-description-edit {
    width: 90%;
    font-size: 13px;
    padding: 5px;
    resize: vertical;
}

/* Power */
.group-power {
    font-size: 14px;
    cursor: pointer;
}

.group-power.readonly {
    color: gray;
    cursor: not-allowed;
}

.group-power-edit {
    width: 100%;
}

/* Permessi */
.permission-name {
    font-weight: bold;
}

.dynamic-permission {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dynamic-permission-text {
    font-size: 14px;
    padding: 4px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dynamic-permission .btn {
    font-size: 12px;
    margin-left: 5px;
}

/* Pulsanti */
.btn-add {
    background-color: #28a745;
    color: white;
    border: none;
}

.btn-add:hover {
    background-color: #218838;
}

.btn-remove {
    background-color: #dc3545;
    color: white;
    border: none;
}

.btn-remove:hover {
    background-color: #c82333;
}

.permission-section-header{
    background-color: #6a6a6a;
}

.save-power-btn, .save-description-btn{
    background-color: transparent;
    width: fit-content;
    height: fit-content;
}
.save-power-btn:hover, .save-description-btn:hover{
    background-color: rgba(0,0,0,0.2);
}

.delete-btn{
    background-color: rgb(246, 63, 63);
    font-weight: bold;
    width: fit-content;
    height: fit-content;
}