.info-container-title {
    font-size: 36px;
}

.role-badge {
    vertical-align: middle;
    margin-right: 5px;
}

.username {
    font-size: 18px;
    font-weight: bold;
}

.email {
    margin-top: 10px;
}

.status {
    margin-top: 10px;
}

.status-red {
    color: red;
}

.status-green {
    color: green;
}

.info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: white;
    border-radius: 10px;
}

.selectable {
    -webkit-user-select: text; /* Safari */
    -moz-user-select: text; /* Firefox */
    -ms-user-select: text; /* IE 10 and IE 11 */
    user-select: text; /* Standard syntax */
}

.user_tickets-ticket {
    cursor: pointer;
    margin: 25px auto;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
}

.user_tickets-ticket-title {
    font-weight: bold;
}

.user_tickets-ticket-time {
    font-style: italic;
}


@media only screen and (max-width: 850px) {
    .user_tickets-ticketsSection {
        max-width: 100%;
    }

    .user_tickets-ticket {
        max-width: 100%;
        word-wrap: break-word;
    }
}

@media only screen and (max-width: 300px) {
    .user_tickets-ticket {
        max-width: 100%;
    }
}