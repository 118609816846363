.downloadElement {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: 0.5rem 0;
    padding: 0.5rem;
    border-radius: 0.5rem;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 0.5rem #000000;
    transition: all 0.5s ease-in-out;
}

.downloadElement:hover {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0.5rem #ffffff;
}

.downloadTitle {
    color: #ffffff;
    font-size: 1.0rem;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 1rem 0;
}

.downloadDescription {
    color: #ffffff;
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 0 1rem 0;
}

.check::before {
    content: "\2713";
    color: green;
}

{
/* Crea un simbolo di visto (v) verde moderno */

}
.cross:before {
    content: "\2715";
    color: red;
}