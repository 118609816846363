.faqMainBox {
    margin: 0 11%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: white;
    border-radius: 10px;
}

.faqContentBox {
    width: 100%;
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "top top"
                        "faq faq"
                        "bottom bottom";
}

.adminButton {
    margin: 10px;
}

.faqTitle {
    grid-area: top;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
}

.faqArrow::before {
    content: "<";
}

.faqArrow {
    float: right;
    margin-right: 10px;
    cursor: pointer;
    transform: rotate(-90deg);
}

.faqMainContent {
    grid-area: faq;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px auto;
    border-radius: 10px;
    column-gap: 60px;
}

.faqBox {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 20px auto;
    border-radius: 10px;
    grid-area: left;
}

.faqBottom {
    grid-area: bottom;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    border-radius: 10px;
}

.faqForm {
    width: 100%;
    text-align: center;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "title title"
                         "text text"
                         "button button";
}

.faqForm textarea {
    width: 95%;
    max-width: 100%;
}

.question {
    text-align: left;
    grid-area: title;
    width: 100%;
    font-weight: bold;
    font-size: 21px;
    color: #c7c7c7;
    text-shadow: #87000c 1px 0 10px;
}

.faqFormQuestion {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
}

.faqFormAnswer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
}

.answer {
    grid-area: text;
    display: none;
    width: 100%;
    text-align: left;
    padding-top: 10px;
    font-size: 19px;
}

.faqFormButton {
    grid-area: button;
}