.profilesManager__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.profilesManager__content {
    background-color: #262626;
    padding: 2% 10%;
}

.profileManager__userItem {
    position: relative;
    border-radius: 15px;
    border: 1px solid white;
    margin: 2px;
    transition: all 0.3s ease;
    display: inline-grid;
    grid-template-columns: 3fr 1fr;
    width: 70%;
    padding: 5px;
    grid-template-areas: "username role"
                        "email email"
                        "pages pages";
}

.profileManager__search {
    width: 50%;
    display: inline-block;
}

.profileManager__userItemUsername {
    grid-area: username;
    font-size: 26px;
    font-weight: bold;
    color: white;
    text-align: left;
}

.profileManager__userItemEmail {
    grid-area: email;
    font-size: 18px;
    color: white;
}

.profileManager__userItemRole {
    grid-area: role;
    font-size: 22px;
    color: white;
}

.profileManager__userItemActions {
    grid-area: pages;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 25px;
    grid-template-areas: "title title title"
                        "action1 action2 action3";
}

.profileManager__userItemActionTitle {
    margin-top: 15px;
    font-size: 22px;
    color: white;
    text-align: center;
    align-content: center;
    grid-area: title;
}

.profileManager__userItemActionBan {
    grid-area: action1;
    color: white;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    width: fit-content;
    margin: auto;
}

.profileManager__userItemActionRole {
    grid-area: action2;
    color: white;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
}


.profileManager__userPages {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.profileManager__userPageButton {
    border-radius: 5px;
    width: 35px;
    height: 35px;
    text-align: center;
    display: inline-block;
    margin: 2px 5px;
    border: 1px solid white;
    transition: all 0.3s ease;
    cursor: pointer;
}

.profileManager__userPageButton:hover {
    border: 1px solid #00bfff;
    color: white;
}