.role-badge {
    vertical-align: middle;
    margin-right: 5px;
}

.username {
    font-size: 18px;
    font-weight: bold;
}

.email {
    margin-top: 10px;
}

.status {
    margin-top: 10px;
}

.status-red {
    color: red;
}

.status-green {
    color: green;
}

.status-gray {
    color: gray;
}

.info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: white;
    border-radius: 10px;
}

.twitch-connection,
.user-view,
.minecraft-connection,
.telegram-connection {
    margin-top: 20px;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    width: 60%;
}

.connection-title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #a20000;
}

.connection-info {
    margin-bottom: 10px;
}

.selectable {
    -webkit-user-select: text; /* Safari */
    -moz-user-select: text; /* Firefox */
    -ms-user-select: text; /* IE 10 and IE 11 */
    user-select: text; /* Standard syntax */
}

.sub-status,
.vip-status {
    font-weight: bold;
}

.sub-status-red,
.vip-status-red {
    color: red;
}

.sub-status-green,
.vip-status-green {
    color: green;
}

.missing-data {
    color: red;
}