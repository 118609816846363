:root {
    --toolbar-bg: #2a2a2a;
    --toolbar-border: #444;
    --toolbar-hover: #3b3b3b;
    --toolbar-active: #0077ff;
    --button-bg: #c2c2c2;
    --button-padding: 8px;
    --button-radius: 8px;
    --shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    --editor-bg: #222;
    --editor-border: #444;
    --editor-color: #2a2a2a;
    --editor-padding: 0;
    --border-radius: 6px;
    --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.editorWrapper {
    width: 100%;
    box-sizing: border-box;
    font-family: var(--font-family);
}

.toolbar {
    width: 100%;
    background-color: var(--toolbar-bg);
    display: flex;
    flex-wrap: wrap;
    padding: 5px 0;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    border-bottom: 2px solid var(--toolbar-border);
    box-shadow: var(--shadow);
    z-index: 10;
}

.toolbarGroup {
    display: flex;
    align-items: center;
    margin-right: 15px;
    gap: 5px;
    margin-bottom: 5px;
}

.toolbarButton {
    background-color: var(--button-bg);
    border: none;
    cursor: pointer;
    padding: var(--button-padding);
    border-radius: var(--button-radius);
    transition: background 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.toolbarButton img {
    width: 18px;
    height: 18px;
    filter: invert(1);
}

.toolbarButton:hover {
    background-color: var(--toolbar-hover);
}

.active {
    background-color: var(--toolbar-active) !important;
}

.fontSizeDropdown {
    background-color: var(--button-bg);
    color: var(--editor-color);
    border: none;
    padding: var(--button-padding);
    border-radius: var(--button-radius);
    cursor: pointer;
}

.colorIndicator {
    position: absolute;
    bottom: 2px;
    right: 2px;
    width: 12px;
    height: 12px;
    border: 1px solid #fff;
    border-radius: 2px;
}

.editorContainer {
    background-color: var(--editor-bg);
    border: 1px solid var(--editor-border);
    max-height: 500px;
    padding: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    margin-top: 0;
    box-sizing: border-box;
    text-align: left;
}

.editorContent {
    width: 100%;
    min-height: 200px;
    max-height: 100%;
    outline: none;
    word-wrap: break-word;
    white-space: pre-wrap;
    font-size: 16px;
}

.editorContent * {
    max-width: 100%;
    box-sizing: border-box;
}

/* I divider (sezioni) sono applicati solo all'interno di .editorContent */
.editorContent section {
    border: 1px dashed var(--toolbar-active);
    margin: 10px 0;
    padding: 5px;
    background: rgba(148, 148, 148, 0.03);
}

.willDelete {
    border-color: red !important;
}

.linkModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

::selection {
    background-color: rgb(157, 206, 255);
    color: unset;
}

.linkModalContent {
    background: #575757;
    padding: 10px;
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
    color: #000;
    min-width: 320px;
    text-align: center;
}

.linkModalContent h3 {
    margin-top: 0;
}

.linkModalContent input[type="text"] {
    margin: 15px 0;
    border: 1px solid #ccc;
    border-radius: var(--button-radius);
}

.linkModalButtons {
    display: flex;
    justify-content: space-around;
    gap: 10px;
}

.linkModalButtons button {
    padding: 8px 16px;
    border: none;
    border-radius: var(--button-radius);
    cursor: pointer;
    background-color: var(--button-bg);
    color: var(--editor-color);
    transition: background 0.2s;
}

.linkModalButtons button:hover {
    background-color: var(--toolbar-hover);
    color: white;
}

.customLink {
    text-decoration: underline;
    color: var(--toolbar-active);
}

.imageMenu {
    position: fixed;
    z-index: 200;
    background: #676767;
    border-radius: var(--button-radius);
    box-shadow: var(--shadow);
    padding: 5px;
    display: flex;
    gap: 5px;
}

/* Il menu per le sezioni viene visualizzato solo all'interno dell'editor */
.editorContent .sectionMenu {
}

.imageMenu button {
    padding: 4px 8px;
    border: none;
    border-radius: var(--button-radius);
    cursor: pointer;
    background-color: var(--button-bg);
    color: var(--editor-color);
    transition: background 0.2s;
}

.imageMenu button:hover {
    background-color: var(--toolbar-hover);
}

.deleteButton {
    background-color: red;
    color: #fff !important;
    border: none;
    padding: var(--button-padding);
    border-radius: var(--button-radius);
    cursor: pointer;
    transition: background 0.2s;
}

.deleteButton:hover {
    background-color: darkred;
}
