/* AdminReports.module.css */
.page__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.page__content {
    background-color: #262626;
    padding: 2% 10%;
}

.MainAdmin_ReportsMainBox {
    margin: auto;
    width: 90%;
    border-radius: 15px;
    padding: 5px;
    color: white;
}