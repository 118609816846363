.infoBox__container {
    position: fixed;
    right: 10px;
    top: 10px;
    z-index: 999;
    width: fit-content;
    max-width: 30%;
}

.infoBox__info {
    position: relative;
    word-wrap: break-word;
    overflow: hidden;
    overflow-wrap: break-word;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    cursor: pointer;
    z-index: 100;
    border: 0;
    height: auto;
    max-height: 0;
    opacity: 0;
    border-radius: 6px;
    transition: all 0.5s ease-in-out;
    font-size: 1rem;
    padding: 0;
}

.activeBox {
    padding: 0 20px;
    max-height: 1000px;
    margin: 20px 0 0;
    opacity: 1;
}

.hidedBox {
    max-height: 0;
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s ease-in-out;
}

.closeBox {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.closeBox:before, .closeBox:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    height: 2px;
    background-color: white;
    transform-origin: center center;
}

.closeBox:before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.closeBox:after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.closeBox:hover:before, .closeBox:hover:after {
    background-color: #f00;
    transition: background-color 0.3s ease-in-out;
}

.warning {
    border-left: 5px solid #FFCC00;
}

.alert {
    border-left: 5px solid #FF6A6A;
}

.success {
    border-left: 5px solid #33CC33;
}

.info {
    border-left: 5px solid #33CCFF;
}

.title {
    font-size: 18px;
    margin: 10px 0;
    font-weight: 600;
    color: #f5f5f5;
    transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out;
}

.text {
    margin: 10px 0;
    height: fit-content;
    font-size: 14px;
    color: #dadada;
    transition: opacity 0.3s ease-in-out;
}

.text pre {
    white-space: break-spaces;
}

.progressBar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    height: 3px;
    background-color: transparent;
    border-radius: 10px;
    overflow: hidden;
    animation: progressBarMovement 3s ease-in-out infinite;
    transition: background-color 0.4s ease-in-out;
}

.warning.progressBar {
    background-color: #FFCC00;
}

.alert.progressBar {
    background-color: #FF6A6A;
}

.success.progressBar {
    background-color: #33CC33;
}

.info.progressBar {
    background-color: #33CCFF;
}

@keyframes progressBarMovement {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

@media screen and (max-width: 550px) {
    .infoBox__container {
        max-width: 50%;
    }
}