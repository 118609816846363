.download__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.download__content {
    background-color: #262626;
    padding: 2% 10%;
}

.download__objects {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.download__title {
    /*Stile come titolo */
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 1rem 0;
}

.download__subtitle {
    /*Stile come sottotitolo */
    color: #ffffff;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 0 1rem 0;
}