.background__body {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
}

.background__div {
    position: relative;
    height: 100vh;
    width: 100vw;
    background-color: transparent;
    background-size: cover;
    background-position: center;

    opacity: 1;
    transition: opacity .5s ease-in-out;
}

.background__invisible {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none; /* Impedisce all'elemento invisibile di ricevere eventi */
}