.mainBox {
    color: white;
    width: 40%;
    min-width: fit-content;
    margin: auto;
    border-radius: 8px;
}

.innerBox {
    margin: 15px;
}

.typeAccess {
    color: #008bdc;
    font-weight: bold;
    font-family: "Montserrat Alternates Bold", serif;
}

.formBox {
    width: fit-content;
    display: none;
    visibility: hidden;
}

.visible {
    display: inline-block;
    visibility: visible;
}

.formContainer {
    width: 100%;
    display: grid;
    grid-row-gap: 20px;
    grid-template-columns: 1fr;
    grid-column-gap: 15px;
    text-align: center;
    align-content: center;
    margin-top: 7px;
    margin-bottom: 7px;
}

/*noinspection ALL*/
.inputError {
    border: 1px solid red;
}

.formContainer label {
    font-size: 20px;
}

.mainBox a {
    color: #008bdc;
    cursor: pointer;
}

.tinyLine {
    font-size: 14px;
}

.checkBoxContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 14px;
}

.checkBoxContainer input {
    vertical-align: middle;
    height: 100%;
}

input, select, option {
    width: 100%;
    margin: 0;
    height: 35px;
    border: 1px solid #545454;
    background-color: #5f5f5f;
    border-radius: 5px;
    font-size: 16px;
    font-family: "Montserrat Alternates", serif;
    color: #e5e5e5;
    cursor: pointer;
    transition: all 0.5s;
}

.errorInput {
    border: 1px solid red;
}

.inputAfterError {
    color: red;
    font-size: 15px;
    text-align: left;
}

input:focus, select:focus, option:focus {
    background-color: #2f2f2f;
}

input[type="checkbox"] {
    height: 28px;
    width: 28px;
    margin-left: 10px;
    margin-right: 10px;
}

.smallerInput input[type="checkbox"] {
    height: 15px;
    width: 15px;
    margin-left: 10px;
    margin-right: 10px;
}

/* Rimuovi outline */
input, select, option {
    outline: none;
}


button {
    background-color: #008bdc;
    border: 0;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 10px;
    color: white;
    transition: all .6s;
    cursor: pointer;
}

button:hover {
    background-color: #005159;
}