.error {
    /*Occupa al 80% la pagina in verticale*/
    min-height: 67vh;
}

.error__container img {
    width: 300px;
}

.error__title {
    font-size: 32px;
    font-weight: bold;
    line-height: 3.1;
}

.error__finalMessage {
    color: #c20000;
    font-weight: 700;
}
