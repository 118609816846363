.events-title {
    width: 100%;
    margin: auto;
}

.events-title img {
    width: 30%;
    margin: auto;
}

.events-video {
    width: 40%;
    margin: auto;
    display: inline-block;
}

.events-video div {
    margin: auto;
    border-radius: 15px;
    overflow: hidden;
    max-width: 95%;
}

.events-link a {
    text-decoration: none;
    color: #a8fff3;
}

.events-faq {
    width: 90%;
    margin: auto;
    padding: 15px;
    border-radius: 15px;
    background-color: #2d2d2d;
}
