socialsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 15px 0
}

socialsTitle {
    font-family: Nunito, sans-serif;
    font-weight: 900;
    font-size: 25px;
    color: #c2c2c2;
    text-shadow: 0 0 4px #252525;
    margin: 0 10px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s ease-in-out;
    min-width: 6%;
}

socialsBody {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 40%;
    column-gap: 12px;
    flex-wrap: wrap;
    height: auto;
}

socialElement {
    font-family: Nunito, sans-serif;
    font-size: 15px;
    font-weight: 400;
    width: 180px;
    min-width: 180px;
    margin: 0 0 10px 0;
    display: inline-grid;
    background-color: #3e3e3e;
    border: 1px solid #3e3e3e;
    border-radius: 10px;
    padding: 5px;
    grid-template-columns: 1fr 5fr;
    transition: all .5s;
    cursor: pointer;
}

socialElement img {
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
}

socialElementText {
    color: #c2c2c2;
    text-decoration: none;
    transition: all .3s ease-in-out;
}

socialElement:hover {
    background-color: #313131;
    border: 1px solid #252525;
    transform: scale(1.1);
}