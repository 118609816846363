.serverPage__modalitiesBox {
    background-color: #262626;
    box-shadow: 0 0 10px #262626;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 4%;
}
.serverPage__backButton{
    display: flex;
    width: 80%;
}
.serverPage__fullPage{
    display: flex;
    width: 80%;
}
.serverPage__backButton button{
    background-color: #4b69ff;
    padding: 5px 10px;
    float: left;
    margin: 25px 0 0 10px;
    font-size: 16px;
    border-radius: 5px;
}

.editor__container{
    width: 100%;
}

.serverPage__mod {
    cursor: pointer;
    transition: all .5s;
    position: relative;
    width: 80%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    column-gap: 10px;
    border-bottom: 1px solid #727272;
    margin: 20px 0;
    flex-wrap: wrap;
    padding: 0;
}
.serverPage__mod img {
    float: left;
}
.serverPage__modComplete{
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    column-gap: 10px;
    border-bottom: 1px solid #727272;
    margin: 8px 0;
    flex-wrap: wrap;
    padding: 0;
}
.serverPage__modComplete img {
    float: left;
}

.serverPage__mod:hover {
    transform: scale(1.03);
}

.serverPage__editButtons{
    position: static;
    margin-bottom: 5px;
    top: 0;
    left: 0;
}

.serverPage__editButtons button{
    background-color: #6a6a6a;
    padding: 5px 10px;
    margin: 0 10px;
    font-size: 16px;
    border-radius: 5px;
}

.serverPage__mod_image {
    height: 100%;
}
.serverPage__mod_text {
    text-align: left;
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    width: 100%;
}

.serverPage__mod_bigtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Nunito, sans-serif;
    font-size: 48px;
    font-weight: 900;
    width: 100%;
}
.serverPage__mod_title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 20px;
    font-family: Nunito, sans-serif;
    font-size: 26px;
    font-weight: 900;
    width: 100%;
}

.serverPage__mod_description {
    width: 100%;
    font-family: Nunito, sans-serif;
    text-shadow: #3e3e3e 0 0 10px;
    font-size: 20px;
    font-weight: 400;
    z-index: 10;
}

.serverPage__mod_description img{
    border-radius: 10px;
    margin-right: 15px;
}