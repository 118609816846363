.whoami_box {
    margin: 0 0 0 0;
    height: fit-content;
    padding: 25px 0;
    width: 80%;
    text-align: left;
}

.title {
    font-family: Nunito, sans-serif;
    font-weight: 900;
    font-size: 28px;
    color: #c2c2c2;
    text-shadow: 0 0 4px #252525;
    margin: 30px 0 5px 0;
    height: 40px;
    display: flex;
    justify-content: left;
    align-items: center;
    transition: all .3s ease-in-out;
    min-width: 6%;
}

.text {
    text-align: left;
    margin: 0 20px;
}

.bold {
    font-weight: bold;
}