.loginButton {
    color: white;
    font-size: 1.2em;
    font-weight: bold;
    text-decoration: none;
    transition: all .5s;
    cursor: pointer;
    border: 1px solid gray;
    border-radius: 15px;
    padding: 5px 10px;
    margin: 0 10px;
    background-color: rgba(68, 68, 68, 0.53);
}

.loginButton:hover {
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -o-transform: scale(1.05);
}

.accountHeader__section {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 50px);
    max-width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    padding: 2% 20px;
    margin: 0 auto;
}

.accountHeader__avatarName {
    display: inline-grid !important;
    color: white;
    padding: 2px;
    border-radius: 15px;
    font-size: 16px;
    grid-template-columns: 60px fit-content(100%) fit-content(100%);
    grid-column-gap: 30px;
    justify-items: right;
    align-items: center;
    justify-content: center;
    transition: all .5s;
    cursor: pointer;
    text-align: left;
}

.accountHeader__avatarName:hover {
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -o-transform: scale(1.05);
}


.accountHeader__avatar {
    display: table-cell;
    vertical-align: middle;
}

.accountHeader__avatar img {
    height: 40px;
    line-height: 40px;
}

.accountHeader__name {
    display: inline-block;
}

.accountHeader__name .accountHeader__notificationNumber {
    display: table-cell;
    vertical-align: middle;
    margin: auto;
}

.accountHeader__dropdownElements {
    background-color: rgba(68, 68, 68, 0.53);
    border-radius: 0 0 15px 15px;
    display: none;
    position: absolute;
    color: white;
    min-width: 180px;
    z-index: 100;
    transition: all 0.2s ease-in-out;
}

.notification_number{
    color: #ffffff;
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 44, 44, 0.67);
    width: 25px;
    height: 25px;
    border-radius: 360px;
}

.notificationHeader__dropdownElements {
     background-color: rgba(68, 68, 68, 0.53);
     border-radius: 0 0 15px 15px;
     display: none;
     position: absolute;
     right: 10px;
     color: white;
     min-width: 180px;
     z-index: 100;
     transition: all 0.2s ease-in-out;
 }

.accountHeader__dropdownElement,
.accountHeader__notification_menu {
    transition: all .3s;
    cursor: pointer;
}

.accountHeader__dropdownElement:hover,
.accountHeader__notification_menu:hover {
    transform: scale(1.05);
}

.accountHeader__dropdownMenu {
    color: white;
}

.accountHeader__notification_dropdownElements::-webkit-scrollbar {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 15px;
    width: 10px;
    right: 10px;
}

/* Track */
.accountHeader__notification_dropdownElements::-webkit-scrollbar-track {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 15px;
    background: #f1f1f1;
}

/* Handle */
.accountHeader__notification_dropdownElements::-webkit-scrollbar-thumb {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 15px;
    background: #888;
}

.notificationHeader__dropdownElements{
    height: 60vh;
    overflow: auto;
}

/* Handle on hover */
.notificationHeader__dropdownElements::-webkit-scrollbar-thumb:hover {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 15px;
    background: #555;
}

.notificationHeader__dropdownElements > .accountHeader__dropdownElement {
    transition: none !important;
}

.notificationHeader__dropdownElements > .accountHeader__dropdownElement:hover {
    transform: none !important;
}

.notificationHeader__dropdownElements > .accountHeader__dropdownElement {
    margin-bottom: 10px;
}

.accountHeader__notification_readAll {
    text-align: center !important;
    color: #5fd4ff;
}

/* Gestione Notifiche */
.accountHeader__notification_menu {
    color: white;
    position: relative;
    width: 100%;
    z-index: 100;
    overflow: hidden;
}

.accountHeader__notification_number {
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    font-size: 0.8em;
    border-radius: 50%;
    font-weight: bold;
    transition: all .5s;
    cursor: pointer;
}

#accountHeader__notification_elementContainer {
    border-radius: 0 15px 15px 15px;
    color: white;

    background-color: rgba(68, 68, 68, 0.53);
    position: absolute;
    max-height: 300px;
    top: 0;
    right: -200%;
    overflow-y: initial;
    overflow-x: hidden;
    width: 200%;
    text-align: center;
    align-content: center;
}

#accountHeader__notification_elementContainer .dropdownElement {
    padding: 15px 15px 5px;
}

.accountHeader__notification_box {
    width: 90%;
    height: auto;
    margin: 6px auto;
    overflow: hidden;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid white;
    grid-template-areas: "title title"
    "time time"
    "content content";
}

.accountHeader__notification_title {
    font-size: 1.1em;
    grid-area: title;
    font-weight: bold;
    text-align: left;
}
.accountHeader__notification_postId{
    color: #a5ffe8;
}

.accountHeader__notification_time {
    grid-area: time;
    font-size: 0.8em;
}

.accountHeader__notification_text {
    margin-top: 10px;
    color: #d2d2d2;
    grid-area: content;
    font-size: 1em;
    text-wrap: wrap;
    text-align: left;
}

.accountHeader__notification_dropdownElements {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    color: #2f2f2f;
    min-width: 180px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 100;
    transition: all 0.2s ease-in-out;
}