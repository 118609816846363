.guide__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
    text-align: left;
}

.guide__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
    padding: 10px 10px;
    text-align: left;

    border: 1px solid #5a5a5a;
    border-radius: 10px;
    box-shadow: 0 0 10px #000000;
    background-color: #434343;
}

h1 {
    text-align: center;
    font-size: 1.6rem;
    font-weight: 600;
    color: #ffffff;
    margin: 1rem 0;
}