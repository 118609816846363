.serverHelp__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.serverHelp__content {
    background-color: #262626;
    padding: 2% 10%;
}