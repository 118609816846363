.dropdownMenu {
    position: relative;
    display: table-cell;
    z-index: 100;
    vertical-align: middle;
    height: 100%;
    cursor: pointer;
}

.dropdownText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    height: 100%;
    text-decoration: none;
    text-transform: none;
    white-space: nowrap;
    width: fit-content;
    align-content: center;
    vertical-align: middle;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.dropdownTextElement {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    height: 100%;
    text-decoration: none;
    text-transform: none;
    white-space: nowrap;
    align-content: center;
    vertical-align: middle;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.dropdownText:hover {
    color: #000;
}

.dropdownText div {
    display: inline-block;
}


.dropdownElements {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    color: #2f2f2f;
    min-width: 180px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 100;
    transition: all 0.2s ease-in-out;
}

.dropdownElement {
    cursor: pointer;
}

.dropdownElement:not(.dropdownSpace):hover {
    font-weight: bold;
}

.dropdownSpace {
    cursor: default;
}

.dropdownChar {
    display: inline-block;
    transition: transform 0.5s ease-in-out;
}

.dropdownInverted {
    transform: rotate(180deg);
}