.erenblaze__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.erenblaze__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 0;
    height: fit-content;
    box-shadow: 0 -5px 20px #262626; /* horizontal-offset vertical-offset 'blur' colour */
    background-color: #262626;
    padding: 25px 0;
}

.subPageSelector {
    display: flex;
    background-color: #333;
    padding: 0;
    margin: 0;
    list-style-type: none;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
}

.subPageSelector__item {
    padding: 12px 16px;
    text-decoration: none;
    color: white;
    cursor: pointer;
    transition: background-color .5s ease-in-out, color .5s ease-in-out;
}

.subPageSelector__item:hover {
    background-color: #111;
}

.activeItem {
    background-color: #212121;
}

.subPages {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 0;
    height: fit-content;
    box-shadow: 0 -5px 20px #262626; /* horizontal-offset vertical-offset 'blur' colour */
    background-color: #262626;
    padding: 25px 0;
}