.header__content {
    width: 100%;
    height: 40px;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.header__developmentBanner{
    background-color: #5d0505;
    font-size: 32px;
    font-weight: bold;
    min-height: 40px;
    height: fit-content;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    margin: auto;
}

.header__button {
    text-shadow: 4px 4px 4px #252525; /* horizontal-offset vertical-offset 'blur' colour */
    font-family: "Montserrat Alternates Bold", serif;
    margin: 0 15px;
    height: 40px;
    color: #d0d0d0;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s ease-in-out;
    cursor: pointer;
    max-width: 10%;
    flex: 1 1 0;
}

.header__active {
    border-bottom: 3px solid #00b2ff;
}

.header__special {
    text-shadow: 0 0 10px #0059ff; /* horizontal-offset vertical-offset 'blur' colour */
}

.header__evidenced {
    text-shadow: 0 0 1px #252525; /* horizontal-offset vertical-offset 'blur' colour */
    box-shadow: 0 0 20px #00b2ff; /* horizontal-offset vertical-offset 'blur' colour */
    color: transparent;
    padding: 2px 5px;
    background-color: #00b2ff;
    border-radius: 50px;
    font-weight: bold;
}

.header__button:hover {
    text-shadow: 0 0 7px #0072bb; /* horizontal-offset vertical-offset 'blur' colour */
}

.header__special:hover {
    color: #0066ff;
}

.header__evidenced:hover {
    text-shadow: 0 0 1px #252525; /* horizontal-offset vertical-offset 'blur' colour */
    color: transparent;
    background-color: #5fabff;
}


.header__firstEmpty {
    height: 25%;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5% 0 1% 0;
    transition: all .6s;
}

.header__firstEmpty img {
    width: calc((15vh + 15vw) / 2);
    cursor: pointer;
    border-radius: 25px;
}

.header__secondEmpty {
    height: 18%;
    width: 100%;
    margin: 2% 0 1% 0;
    transition: all .6s;
}


@media only screen and (max-width: 750px) {
    .header__content {
        flex-wrap: wrap;
    }

    .header__button {
        max-width: 100%;
        flex: 1 1 0;
        margin: 0 10px;
    }

    .header__evidenced {
        order: -1;
    }

    .header__secondEmpty {
        margin: 2% 0 8% 0;
    }
}

@media only screen and (max-width: 370px) {
    .header__secondEmpty {
        margin: 2% 0 40% 0;
    }
}

@media only screen and (max-width: 230px) {
    .header__secondEmpty {
        margin: 2% 0 60% 0;
    }
}