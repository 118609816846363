.categoriesMainBox {
    text-align: center;
    margin: auto;
    width: 95%;
    background-color: transparent;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 15px;
}

.catsMain {
    width: 100%;
    background-color: transparent;
    font-family: 'Roboto', sans-serif;
    display: inline-grid;
    grid-template-columns: 8fr 4fr;
}

.categoryBoxSeparator {
    width: 65% !important;
    margin: 35px 0 0 0 !important;
}
.categoryBox {
    width: 45%;
    height: auto;
    border: 1px solid #adadad;
    font-size: 22px;
    border-radius: 5px;
    margin: 5px;
    padding: 8px;
    background-color: transparent;
    box-shadow: 0 0 5px #adadad;
    display: inline-grid;
    grid-template-columns: 3fr 2fr 1fr;
    grid-template-areas: "name name order"
                        "usability edit disable";
    text-align: center;
    grid-row-gap: 5px;
    transition: all .5s;
}

.categoryCreateBoxNames {
    display: inline-grid;
    column-gap: 25px;
    grid-template-columns: 3fr 1fr;
}

.categoryEnabled {
    box-shadow: 0 0 10px green;
}

.categoryDisabled {
    box-shadow: 0 0 10px red;
}

.categoryBox select {
    width: auto;
}

.categoryBox input {
    width: auto;
}

.categoryName {
    overflow: hidden;
    font-size: 22px;
    text-align: left;
    width: 100%;
    white-space: nowrap;
    grid-area: name;
}

.categoryName[title]:hover::after {
    content: attr(title);
    border: 1px solid #414141;
    border-radius: 10px;
    padding: 5px;
    background-color: #333333;
    position: absolute;
    top: 2%;
    left: 0;
}

.categoryUsab {
    width: 90%;
    white-space: nowrap;
    grid-area: usability;
}

.categoryOrder {
    width: 90%;
    grid-area: order;
}

.categoryOrder input {
    width: 100%;
    height: 90%;
}

.categorySelector {
    width: auto;
    height: 90%;
}

.categoryDisable {
    width: fit-content;
    grid-area: disable;
    cursor: pointer;
    border: 1px solid #494949;
    border-radius: 10px;
    padding: 0 10px;
    background-color: #730011;
}

.categoryDisable div {
    transition: all .5s;
}

.categoryDisable div:hover {
    transform: scale(1.1);
}

.categoryEdit {
    width: fit-content;
    grid-area: edit;
    border: 1px solid #494949;
    border-radius: 10px;
    padding: 0 10px;
    background-color: #343f80;
}

.categoryEdit div {
    cursor: pointer;
}

.categoryRight {
    display: inline-grid;
    grid-template-columns: 6fr 4fr;
}

.categoryList {
    width: 100%;
}

.categoryListTitle {
    font-size: 20px;
    font-weight: bold;
    margin: 5px;
    text-align: center;
}

.categoryCreateTitle {
    font-size: 20px;
    font-weight: bold;
    margin: 5px;
    text-align: center;
}

.categoryClearButton {
    background-color: #5a1414;
    color: white;
    border: 1px solid #5a1414;
    border-radius: 5px;
    margin: auto;
    cursor: pointer;
    transition: all .5s;
    height: 30px;
    width: 30px;
}

.categoryListBox {
    width: 100%
}
.categoryListBoxDisabled{
    width: 100%;
    border-top: 1px solid white;
    margin-top: 15px;
    padding-top: 15px
}

.categoryCreateBox {
    border: 1px solid #adadad;
    border-radius: 5px;
    background-color: transparent;
    box-shadow: 0 0 5px #adadad;
    display: inline-grid;
    grid-template-columns: 1fr;
    text-align: center;
    margin: auto;
    width: 95%;
    grid-template-areas:
    "top top"
    "bottom bottom";
}

.categoryCreateBoxTopLeft {
    grid-area: top;
    margin: 10px;
}

.categoryCreateBoxBottom {
    grid-area: bottom;
    margin: 10px;
}

.categoryBox:hover {
    transform: scale(1.05);
}