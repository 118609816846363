.footer___Background {
    background-color: #1f1f1f;
    width: auto;
    padding: 2% 10%;
    z-index: 200;
}

.footer__content {
    color: white;
    width: 100%;
    display: flex;
    column-count: 3;
    flex-wrap: wrap;
    column-gap: 5%;
}

.footer__content > div {
    flex: 0 0 28%;
}

.footer___box {
    display: grid;
    text-align: left;
    margin: 0 0 1% 0;
}

.footer___box_centered {
    flex: 1 0 100% !important;
    text-align: center;
    margin: 0 0 1% 0;
}

.footer___title {
    font-family: Nunito, sans-serif;
    font-size: 28px;
    font-weight: 900;
    width: 100%;
}

.footer___title::after {
    content: "\a";
    white-space: pre;
}

.footer___text {
    font-family: Nunito, sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #b7b7b7;
    width: 100%;
}

.footer___text a {
    cursor: pointer;
    color: white;
    text-decoration: underline;
}

.footer___links{
    cursor: pointer;
    color: white;
    text-decoration: underline;
}

.footer___links::after {
    content: "\a";
    white-space: pre;
}

.footer___text a:hover {
    color: #ababab;
}
.footer___links:hover {
    color: #ababab;
}

.footer___button {
    color: white;
    background-color: #5fabff;
    border-radius: 5px;
    width: 80%;
}

.footer___button:hover {
    background-color: #2a77c9;
}

.footer___copyright {
    align-self: center;
    font-family: Nunito, sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #b7b7b7;
    width: 100%;
}

.footer__footer {
    text-align: left;
    justify-content: space-between; /* Distribuisce uniformemente gli elementi */
    color: white;
    width: auto;
    display: flex;
    flex-wrap: wrap;
    column-gap: 5%;
    row-gap: 25px;
    background-color: #151515 !important;
    padding: 2% 10%;
}

.footer__footer > div {
    flex: 1;
    font-size: 16px;
}
.footer__footer_left {
    text-align: left;
}

.footer__footer_center {
    text-align: center;
}

.footer__footer_right {
    text-align: right;
}

.general_title {
    font-family: "Montserrat Alternates Bold", serif;
    font-weight: 900;
    font-size: 23px;
}

.general_text {
    font-family: "Montserrat Alternates", serif;
    font-weight: 400;
    font-size: 15px;
}

.blue_color {
    color: #7c7cb9;
}

a {
    cursor: pointer;
}


@media only screen and (max-width: 1100px) {
    .footer__content {
        column-count: 1;
    }

    .footer__content > div {
        flex: 0 0 100%;
        margin: 40px 0;
        border-bottom: 1px solid #5e5e5e;
    }

    .footer__content > div::after {
        content: '';
    }

    .footer__content > div:last-child {
        border-bottom: none;
    }

    .footer___button {
        margin: 10px auto;
        width: 10%;
        min-width: fit-content;
    }
}