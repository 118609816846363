.Post_ReportsMainBox {
    margin: auto;
    width: 90%;
    border-radius: 15px;
    padding: 5px;
    color: white;
}

.post_comment {
    margin: 15px auto;
    text-align: left;
    width: 100%;
    border-radius: 15px;
    padding: 5px;
    color: white;
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "commentAuthor commentDate"
                            "commentBody commentBody";
}

.post_comment_header {
    grid-area: commentAuthor;
    font-size: 1.2rem;
    font-weight: bold;
    width: fit-content;
    margin: 0;
}

.post_comment_date {
    grid-area: commentDate;
    font-size: 0.8rem;
    margin: 0;
    text-align: right;
}

.post_comment_body {
    grid-area: commentBody;
    font-size: 0.9rem;
    margin: 0;
    padding-top: 10px;
}

.postBody img,
.post_comment_body  img {
    max-width: 50%;
    float: left;
    margin-right: 5px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin-top: 5px;
    /* ... */
}

/* Se l'immagine è dentro un div.draggable, lo stile viene sovrascritto */
.draggable img {
    float: none !important;
    width: 100% !important;
    height: auto !important;
    pointer-events: none; /* così trascini il div, non l'immagine stessa */
}

.PostInfo {
    /* Allinea tutto il contenuto a sinistra */
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    padding-top: 0px;
    display: inline-grid;
    align-items: end;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "postTitle postDate"
                            "postStatus ."
                            "postCategory ."
                            "postAuthor ."
                            "postContent postContent";
}

.page-item {
    font-size: 0.7rem;
    display: inline-block;
    margin: 0 1px;
    padding: 3px 3px;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
}

.page-item:hover {
    background: #ddd;
}

.page-item-active {
    background: #333;
    color: #fff;
}

.page-item-active:hover {
    background: #333;
    color: #fff;
}

.postTitle {
    font-size: 1.4rem;
    font-weight: bold;
    grid-area: postTitle;
    margin: 0;
}

.postCategory {
    font-size: 0.8rem;
    font-weight: bold;
    grid-area: postCategory;
    margin: 0;
}

.postStatus {
    font-size: 0.8rem;
    font-weight: bold;
    grid-area: postStatus;
    margin: 0;
    padding-bottom: 20px;
}

.postDate {
    font-size: 0.7rem;
    grid-area: postDate;
    margin: 0;
    text-align: right;
}

.postAuthor {
    font-size: 0.7rem;
    grid-area: postAuthor;
    width: fit-content;
    margin: 0;
}

.postBody {
    padding-top: 20px;
    font-size: 0.9rem;
    border-radius: 8px;
    grid-area: postContent;
    width: 94%;
    margin: auto;
    margin-bottom: 0.5rem;
}

.postBody *, .post_comment_body *, .postBody, .post_comment_body {
    font-size: 0.8rem;
}

.postBody, .postBody *, .post_comment_body *, .postBody, .post_comment_body {
    user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    -webkit-user-select: text !important;
}

.post_comment > a {
    color: #36a4ff;
}

.post_comment a:visited {
    color: #36a4ff;
}

.ql-editor > * {
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text !important;
}

.PostComments {
    width: 100%;
}

.post_comment {
    width: 90%;
}

.post_comment_separator {
    content: "";
    margin: auto;
    display: inline-block;
    width: 90%;
    vertical-align: middle;
    border-top: 1px white solid;
}

.post_buttons {
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "postEdit postDelete";
    width: 100%;
    margin: 20px 0 0;
    grid-column-gap: 20px;
}

.post_buttons > *:nth-child(odd) {
    justify-self: end;
    margin-right: 10px;
}

.post_buttons > *:nth-child(even) {
    justify-self: start;
    margin-left: 10px;
}

.post_buttons button {
    width: fit-content;
}

.post_adminButtons {
    display: inline-block;
    width: 100%;
    margin: 20px 0 0;
    grid-column-gap: 20px;
}

.post_adminButtons select {
    width: 30%;
}

.postBigImageBackground {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.imageButtons {
    margin: auto 10px;
}

.imageBox {
    position: relative;
    height: fit-content;
    width: fit-content;
    max-height: 90vh;
    max-width: 90vw;
    overflow: auto;
    border: 2px solid #afafaf;
    background-color: black;
    border-radius: 20px;
}

#zoomableImage {
    transform-origin: top left;
    height: fit-content;
    width: fit-content;
}

.imageContainer {
    display: grid;
}