/* CommonReportsPage.module.css */

/* Contenitore principale */
.CommonReportsPage {
    width: 100%;
    color: white;
}

/* Riga dei filtri: open/closed + input search + pulsante create */
.filtersRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
}
.filtersRow input[type=text]{
    margin: 0 0 0 20px
}
.statusFilter {
    border: 1px solid #8f8f8f;
    border-radius: 5px;
    color: #fff;
    display: grid;
    font-family: Montserrat Alternates Bold;
    font-size: 22px;
    width: 90%;
}
.statusFilter span{
    font-size: 25px;
}
.statusFilter > * {
    margin: 2px 0;
}

/* Container per i report */
.reportsContainer {
    display: block;
    grid-area: reports;
}

.ReportsPageLayout {
    grid-column-gap: 15px;
    align-items: start;
    display: grid;
    grid-template-areas: "filters reports";
    grid-template-columns: 1fr 6fr;
    justify-content: center;
    margin: auto;
    width: 100%;
}

/* Paginazione in basso */
.paginationRow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}
.pagesSelector{
    width: 50px;
}

/* Stile del singolo box report
   (equivalente di .MainUser_ReportBox / .MainAdmin_ReportBox).
   Se i due file precedenti usano la stessa griglia,
   unifichiamo qui la regola.
*/
.ReportBox {
    background-color: #595959;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    font-size: 20px;
    margin: 20px 0;
    transition: all .5s;
    width: 100%;
}

.reportUsername {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 5px auto;
    width: 100%;
}

.reportTitle {
    font-weight: 700;
    margin: 5px 0;
    width: 100%;
}

.reportCategory {
    margin: 5px 0;
    width: 40%;
}

.reportStatus {
    margin: 5px 0;
    width: 20%;
}

.reportTime {
    margin: 5px 0;
    width: 40%;
}

/* Se vuoi un'icona dell'avatar */
.reportImage {
    border-radius: 20px;
    margin: 0 2px;
    width: 30px;
}