.profileMainBox {
    text-align: left;
    width: 80%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 20px;
    color: #ffffff;
    border-radius: 10px;
}

.profileGrid {
    display: inline-grid;
    grid-template-columns: 1fr 3px 1fr;
    column-gap: 60px;
    row-gap: 30px;
    grid-template-areas: "left space right"
                        "leftB space rightB"
                        "big big big";
}

.columnBorder {
    grid-area: space;
    border-left: 2px solid transparent;
    height: 100%;
    border-image: linear-gradient(white, #ffffff, white) 1 stretch;
}

.connectionsInfo {
    margin: 20px;
}

.sectionTitle {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
}
.sectionTinyInfo{
    font-size: 20px;
    color: #b9b6b6;
}

.centerBox {
    width: 100%;
    display: inline-grid;
    text-align: center;
}

.connectionBox {
    margin: 20px;
    text-align: left;
    font-size: 20px;
}

.profileContainer {
    grid-area: left;
}

.connectionButton {
    width: 100%;
    text-align: center;
    grid-area: leftB;
}

.connected {
    color: #159200;
}

.goldColor {
    color: gold;
}

.centerButton {
    margin: 0 auto;
}

.notConnected {
    color: red;
}

.infoContainer {
    grid-area: right;
}

.infoButton {
    width: 100%;
    text-align: center;
    grid-area: rightB;
}

.infoChange {
    margin: 20px;
    text-align: center;
}

.accountInfoFormRow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.accountInfoFormRow :first-child {
    width: 37%;
    margin-right: 25px;
}

.accountInfoFormRow label {
    text-align: left;
}

.adminButtons {
    margin-top: 40px;
    grid-area: big;
    text-align: center;
    font-size: 20px;
    display: inline-grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
}

.adminButton button {
    width: fit-content;
    text-align: center;
    margin: 20px;
}