.selected {
    background-color: #000000 !important;
}

.tos__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.tos__content {
    background-color: #262626;
    padding: 2% 10%;
}

.dateButton button {
    background-color: #878787;
    border: none;
    color: white;
    padding: 3px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 2px 4px;
    cursor: pointer;
    border-radius: 25px;
    transition: all 0.75s;
}
