.Tickets_alert {
    font-size: 1.2em;
    background-color: #f2dede;
    color: #a94442;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #c76363;
    border-radius: 12px;
}

.Tickets_smaller-text {
    font-size: 0.6em;
}

.Tickets_ReportsMainBox {
    margin: auto;
    width: 90%;
    border-radius: 15px;
    padding: 5px;
    color: white;
}

.Tickets_ReportsTitle {
    color: #980000;
    font-size: 28px;
    font-family: "Montserrat Alternates Bold";
}

.Tickets_reportSuperBox {
    margin: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 6fr;
    grid-template-areas:
    "reports reports"
    "reports reports";
}

.Tickets_mainFormInfo {
    margin: auto;
    width: 75%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.Tickets_mainFormInfo input {
    width: 90%;
}

.Tickets_mainFormInfo select {
    width: 90%;
}

.Tickets_ReportsTableBox {
    grid-area: reports;
    margin: auto;
    display: block;
    width: 90%;
    color: #dfdfdf;
}

.Tickets_ReportBox {
    display: grid;
    grid-column-gap: 12px;
    grid-template-columns: 1fr 2fr 5fr 1fr;
    grid-template-areas: "time category title status";
    align-items: center;
    height: 75px;
    overflow: hidden;
    border-bottom: 1px solid #868686;
}

.Tickets_ReportBox div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
    overflow: hidden;
}

.Tickets_ReportBox #reportTime {
    align-items: center;
    grid-area: time;
    font-size: 18px;
    font-family: "Montserrat Alternates Bold";
    color: #980000;
    border-right: 1px solid #868686;
}

.Tickets_ReportBox #reportCategory {
    align-items: center;
    grid-area: category;
    border-right: 1px solid #868686;
}

.Tickets_ReportBox #reportTitle {
    align-items: center;
    grid-area: title;
    border-right: 1px solid #868686;
    text-align: left;
}

.Tickets_ReportBox #reportStatus {
    align-items: center;
    grid-area: status;
}

.Tickets_UpperTable {
    align-self: center;
    color: white;
    font-size: 18px;
    font-family: "Montserrat Alternates Bold";
    justify-content: center;
}

.Tickets_UpperTable #reportTime {
    color: black;
}

.Tickets_ReportBox img {
    max-width: 50%;
}
/* Se l'immagine è dentro un div.draggable, lo stile viene sovrascritto */
.draggable img {
    float: none !important;
    width: 100% !important;
    height: auto !important;
    pointer-events: none; /* così trascini il div, non l'immagine stessa */
}

.Tickets_reportSuperBox #form-Main {
    margin: auto;
    width: 90%;
    border-radius: 15px;
    padding: 5px;
    color: white;
}

.Tickets_reportSuperBox #form-Main form-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 10px;
}

.Tickets_reportSuperBox #form-Main label {
    display: block;
    float: left;
    width: 28%; /* o qualsiasi altra dimensione desiderata */
    text-align: left;
    margin-right: 1%;
}

.Tickets_reportSuperBox #form-Main form-content {
    display: block;
    float: right;
    text-align: left;
    width: 68%; /* o qualsiasi altra dimensione desiderata */
}

.Tickets_reportSuperBox #form-Main comboBox {
    float: left;
    text-align: left;
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 10px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.Tickets_reportSuperBox #form-Main comboBox span {
    margin-right: 4px;
}

.Tickets_reportSuperBox #form-Main comboBox input[type="checkbox"] {
    display: none;
}

.Tickets_reportSuperBox #form-Main comboBox .vis-checkbox {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: #ddd;
    border-radius: 5px;
    border: 1px solid #ccc;
    transition: background-color 0.4s;
}

.Tickets_checked {
    background-color: #ff0000 !important;
}

/* Crea lo stile per il segno di spunta */
.Tickets_checked::after {
    content: "";
    display: block;
    position: relative;
    max-width: 100%;
    width: 33%;
    left: 25%;
    margin-top: 0;
    height: 10px;
    border: solid #dcdcdc;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: all 2.2s;
    animation: checkbox-animation 0.5s ease 0s;
}

.disabledOption {
    background-color: #3e0000 !important;
    color: #d1d1d1 !important;
    text-align: left !important;
}

.Tickets_infoBox {
    margin: auto;
    width: 90%;
    border-radius: 15px;
    padding: 5px;
    color: #ff5555;
    font-size: 18px;
}

.tickets__moreInfoBox {
    display: flex;
    width: 100%;
    align-items: center;
    justify-items: center;
    text-align: center !important;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.tickets__moreInfoBox #formDescription {
    margin: 0 auto;
    width: 80%;
}

.tickets__moreInfoBox label {
    width: 100% !important;
    margin: 20px 0 0 0;
    text-align: center !important;
    white-space: nowrap;
    align-items: center;
    justify-items: center;

}

@keyframes checkbox-animation {
    from {
        opacity: 0;
        transform: rotate(45deg) scale(0);
    }
    to {
        opacity: 1;
        transform: rotate(45deg) scale(1);
    }
}

