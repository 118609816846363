/* PAGINE */
.homeBox {
    display: inline-block;
    width: 70%;
    height: auto;
    margin: 0 auto;
    border-radius: 15px;
    padding: 20px;
    color: white;
    font-size: 24px;
    text-align: left;
}

.centerText {
    width: 100%;
    text-align: center;
}

.homeBox img {
    width: 25%;
}

.info-mainBox {
    text-align: left;
    display: inline-block;
    width: 70%;
    height: auto;
    margin: 0 auto;
    border-radius: 15px;
    padding: 20px;
    color: white;
}

.mainTitle {
    font-size: 2vw;
    font-family: "Montserrat Alternates Bold", serif;
    margin-bottom: 20px;
}

.italic {
    font-style: italic;
    text-wrap: avoid;
}

.info-mainBox .text {
    text-align: left;
    width: 100%;
}

.info-mainBox .text .images {
    text-align: center;
}

.info-mainBox .text img {
    width: 20%;
    height: auto;
    margin: 10px 10px;
    display: inline-block;
    transition: .3s;
}

.info-mainBox .text img:hover {
    transform: scale(1.1);
}

.boxTitle {
    font-family: "Montserrat Alternates Bold", serif;
    font-size: 36px;
    margin: 15px;
    text-align: center;
}

.boxContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    margin: 0 auto;
    border-radius: 15px;
}

.eblDERP {
    width: 15%;
    animation: eblDERP 16s infinite linear;
}

@keyframes eblDERP {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}