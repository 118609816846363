:root {
    --text-color: white;
}

* {
    margin: 0;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    scroll-behavior: smooth;
}

html, body {
    padding: 0;
    text-align: center;
    align-content: center;
    font-size: 24px;
    color: var(--text-color);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    background-color: #262626;
    color: white;
    max-width: 100%;
}

a {
    text-decoration: none;
    color: white;
}


/* FONTS */
@font-face {
    font-family: "Nunito";
    src: url('commons/resources/fonts/Nunito-VariableFont_wght.ttf');
}

@font-face {
    font-family: "Montserrat Alternates Bold";
    src: url('commons/resources/fonts/Montserrat-Black.otf');
}

@font-face {
    font-family: "Montserrat Alternates";
    src: url('commons/resources/fonts/Montserrat-Medium.otf');
}

@font-face {
    font-family: "Montserrat Alternates Light";
    src: url('commons/resources/fonts/Montserrat-Light.otf');
}

/* Scroll Bar */
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

button:disabled {
    background-color: #4d4d4d;
    color: #ffffff;
    cursor: default;
}

button:disabled:hover {
    background-color: #4d4d4d;
    color: #ffffff;
}

button {
    margin: 5px;
}

a {
    color: #2a77c9;
}


.loadingPage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #131313;
    z-index: 999;
    display: block;
}

.loadingCircle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 10px solid darkred;
    border-top: 10px solid rgba(0, 0, 0, 0);
    animation: loadingCircle 1.5s infinite linear;
}

.page__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.page__content {
    background-color: #262626;
    padding: 2% 10%;
}

.loadingCircleTiny {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 5px solid #a20000;
    border-top: 5px solid rgba(0, 0, 0, 0);
    animation: loadingCircle 1.5s infinite linear;
}

.clickable {
    cursor: pointer;
}

@keyframes loadingCircle {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}